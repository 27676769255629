<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.benefits.moments.templates')" class="settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              <span>{{$t('loyalty.Benefits_moment_template')}}</span>
            </CCol>
            <CCol v-if="benefitsMomentTemplateLoading === false" cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-flex justify-content-lg-end">
                <CButton v-if="checkPermission('core.emailtemplates') && emailEditorReady && templates.length > 0" 
                         @click="selectTemplateView = true" 
                         color="secondary" 
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope mr-1"></i>{{$t('common.Load_email_template')}}</span>
                </CButton>
                <CButton v-if="checkPermission('core.emailtemplates') && emailEditorReady && benefitsMomentTemplate.json"
                         @click="openSidebarRight('save_email_template', { template_type: 'benefits-moment-template-email', template_source_id_external: null , product_tag: null })" 
                         color="secondary"
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope-circle-check mr-2"></i>{{$t('common.Save_as_email_template')}}</span>
                </CButton>
                <CButton v-if="emailEditorReady" @click="updateBenefitsMomentTemplate();" color="primary" class="m-0 btn_small">
                  <span><i class="fas fa-check mr-1"/>{{$t('loyalty.Update_benefits_moment_template')}}</span>
                </CButton>                
              </div>             
            </CCol>            
          </CRow>
        </CCardHeader>
        <CCardBody class="event benefits_moment">
          <CRow v-if="benefitsMomentTemplateLoading === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="benefitsMomentTemplateLoading === false">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow class="p-0">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
  
                  <transition name="slide-big">
                    <div v-if="selectTemplateView">
                      <CRow>
                        <CCol cols="12" lg="12" class="pt-0 pb-0">
                          <span>{{$t('common.Pick_mail_template')}}</span>
                        </CCol>
                      </CRow>
                      <CRow class="tile-container">
                        <CCol cols="3" lg="3" class="p-1_5">
                          <navigationCard @click.native="setEmailTemplate(benefitsMomentTemplate, benefitsMomentTemplate.json)" path="" :cardTitle="$t('common.Current_template')" :cardDescription="$t('common.Keep_using_current_template')" :cardIcon="null" class="h-100 m-0"/>
                        </CCol>                                     
                        <CCol v-for="template in templates" v-bind:key="template.platform_event_email_template_id_external" cols="3" lg="3" class="p-1_5">
                          <navigationCard @click.native="setEmailTemplate(benefitsMomentTemplate, template.json)" path="" :cardTitle="template.name" :cardDescription="template.description" :cardIcon="null" class="h-100 m-0"/>
                        </CCol>
                      </CRow>
                    </div>
                  </transition>

                  <CRow class="p-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.email')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="benefitsMomentTemplate.subject" required was-validated/>
                            </CCol>
                          </CRow>
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <label class="mt-0 mb-2">{{$t('communications.email_content')}}</label>
                              <div v-if="benefitsMomentTemplate.email_content_tags" class="mb-1">
                                <span>{{ $t('communications.available_tags') }} {{benefitsMomentTemplate.email_content_tags}}</span>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12">
                              <EmailEditor :ref="'emailEditor_' + benefitsMomentTemplateIdExternal"
                                           :appearance="emailEditorConfig.appearance"
                                           :min-height="emailEditorConfig.minHeight"
                                           :project-id="emailEditorConfig.projectId"
                                           :locale="emailEditorConfig.locale"
                                           :tools="emailEditorConfig.tools"
                                           :options="emailEditorConfig.options"
                                           v-on:ready="editorReady(benefitsMomentTemplate)">
                              </EmailEditor>
                            </CCol>
                          </CRow>               
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>

                  <!-- <CRow>
                    <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.points')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">                         
                          <CRow class="w-100 m-0">
                            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="h-100">
                              <CInput @input="countdown()" type="text" maxlength="50" :label="$t('communications.Points_title')" class="mt-0 mb-1" v-model="benefitsMomentTemplate.points_title"/>
                              <span class="countdown" v-if="remainingCount.points_title > 0">{{remainingCount.points_title}} {{$t('common.characters_remaining')}}</span>
                              <CTextarea @input="countdown()" rows="2" maxlength="200" :label="$t('communications.Points_description')" class="mt-3 mb-1" v-model="benefitsMomentTemplate.points_description"/>
                              <span class="countdown" v-if="remainingCount.points_description > 0">{{remainingCount.points_description}} {{$t('common.characters_remaining')}}</span>
                            </CCol>
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow> -->

                  <CRow>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.push')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">             
                          <CRow class="w-100 m-0">
                            <CCol cols="4" lg="4">
                              <CInput @input="countdown()" type="text" maxlength="20" :label="$t('communications.Push_title')" class="mt-0 mb-1" v-model="benefitsMomentTemplate.push_title"></CInput>
                              <span class="countdown" v-if="remainingCount.push_title > 0">{{remainingCount.push_title}} {{$t('common.characters_remaining')}}</span>
                            </CCol>
                            <CCol cols="8" lg="8">
                              <CInput @input="countdown()" type="text" maxlength="60" :label="$t('communications.Push_message')" class="mt-0 mb-1" v-model="benefitsMomentTemplate.push_message"/>
                              <span class="countdown" v-if="remainingCount.push_message > 0">{{remainingCount.push_message}} {{$t('common.characters_remaining')}}</span>
                            </CCol>                              
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>           
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { EmailEditor } from 'vue-email-editor';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import navigationCard from '@/components/common/navigationCard';

export default {
  name: 'Moment',
  components: {
    Multiselect,
    EmailEditor,
    loadingSpinner,
    noPermission,
    navigationCard    
  },
  watch: { 
    '$route.params.id': {
      handler() {        
        this.getBenefitsMomentTemplateDetails();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      benefitsMomentTemplateIdExternal: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      emailEditorInstructionsRead: false,
      apiBaseUrl: null,
      clientToken: null,
      company: {
        details: {
          environment_name: null
        }
      },
      benefitsMomentTemplate: {},
      benefitsMomentTemplateLoading: false,
      benefitsMomentTemplateLoaded: false,      
      templates: [],      
      selectTemplateView: false,
      emailEditorReady: false,
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935, //'50935' = Harry HR
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {
          mergeTags: {
            name: {
              name: "Employee Name",
              value: "{$name}"
            },
            ceo_name: {
              name: "Email Signatory",
              value: "{$email_signatory}"
            },            
            company: {
              name: "Platform Name",
              value: "{$platform_name}"
            },
            points: {
              name: "Points",
              value: "{$points}"
            }, 
            total_points: {
              name: "Total Employee Points",
              value: "{$total_employee_points}"
            },
            compliment: {
              name: "Compliment",
              value: "{$compliment}"
            },            
            copyright: {
              name: "Copyright",
              value: "{$copyright}"
            }                          
          },          
          specialLinks: [
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Link to Dashboard',
                  href: '{$dashboard_url}',
                  target: '_blank',
                },
                {
                  name: 'Link to Experience',
                  href: '{$app_url}',
                  target: '_blank',
                }
              ]
            }
          ]
        },
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        }              
      },    
      maxCount: {
        points_title: 50,
        points_description: 200,
        push_title: 20,
        push_message: 60
      },
      remainingCount: {
        points_title: 50,
        points_description: 200,        
        push_title: 20,
        push_message: 60
      }              
    }
  },
  methods: {
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getBenefitsMomentTemplateDetails() {
      // Start the loader
      if(!this.benefitsMomentTemplateLoaded) this.benefitsMomentTemplateLoading = true;
      // Get the event data
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/benefits/moments/template')
      .then(res => {
        this.benefitsMomentTemplate = res.data.data;
        // Set the benefitsMomentTemplateIdExternal value
        this.benefitsMomentTemplateIdExternal = res.data.data.loyalty_benefits_moment_template_id_external;
        // Trigger the countdown method
        this.countdown();
        // Stop the loader
        this.benefitsMomentTemplateLoading = false;
        // Update the benefitsMomentTemplateLoaded value
        this.benefitsMomentTemplateLoaded = true;                      
        // Check if the Benefits Moment template contains a valid JSON
        if(this.benefitsMomentTemplate.json) {
          // Set emailEditorName variable
          let emailEditorName = `emailEditor_${this.benefitsMomentTemplateIdExternal}`;
          // If so, set the mailDesign in the editor
          let mailDesign = JSON.parse(this.benefitsMomentTemplate.json);
          
          setTimeout(function() {
            if(this.$refs[emailEditorName] && this.$refs[emailEditorName].editor) {
              this.$refs[emailEditorName].editor.loadDesign(mailDesign);
            }
          }.bind(this), 2000);
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },    
    updateBenefitsMomentTemplate() {
      let params = {};      
      params.benefitsMomentTemplateData = this.benefitsMomentTemplate;      
                
      if(!this.benefitsMomentTemplate.json) params.benefitsMomentTemplateData.json = null;
      if(!this.benefitsMomentTemplate.html) params.benefitsMomentTemplateData.html = null;      

      // Set emailEditorName variable
      let emailEditorName = `emailEditor_${this.benefitsMomentTemplateIdExternal}`;

      if(this.$refs !== undefined && this.$refs[emailEditorName] !== undefined ) {
        this.$refs[emailEditorName].editor.saveDesign((design) => {
          params.benefitsMomentTemplateData.json = JSON.stringify(design);
        });

        this.$refs[emailEditorName].editor.exportHtml((data) => {
          params.benefitsMomentTemplateData.html = data.html;
        })
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to the params
      setTimeout(function() {
        // Validate the Benefits Moment template
        if(this.validateBenefitsMomentTemplate(params) === true) {        
          axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/benefits/moments/template/' + this.benefitsMomentTemplateIdExternal, params)
          .then(res => {
            this.$buefy.toast.open({ message: this.$t('loyalty.Benefits_moment_template_updated'), type: 'is-success', duration: 2000 });
            // Get the Benefits Moment template data
            this.getBenefitsMomentTemplateDetails();
          })
          .catch(err => {
            console.error(err);
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          });
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);      
    },
    validateBenefitsMomentTemplate(params) {
      let subject = params.benefitsMomentTemplateData.subject;
      let json = params.benefitsMomentTemplateData.json;   
      let push_title = params.benefitsMomentTemplateData.push_title;
      let push_message = params.benefitsMomentTemplateData.push_message;

      // Check if all data for the Benefits Moment template is available
      if(subject && json && push_title && push_message) {
        return true;
      } else {
        return false;
      }
    },    
    getEmailTemplates() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/templates/list')
      .then(res => {      
        this.templates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });              
    },
    setEmailTemplate(benefitsMomentTemplate, templateData) {
      // Update selectTemplateView value to close the view
      this.selectTemplateView = false;
      // Set emailEditorName variable
      let emailEditorName = `emailEditor_${benefitsMomentTemplate.loyalty_benefits_moment_template_id_external}`;
      // Convert template data to JSON
      let mailDesign = JSON.parse(templateData);
      // Load the JSON into the editor
      setTimeout(function(){          
        this.$refs[emailEditorName].editor.loadDesign(mailDesign);
      }.bind(this), 1000);
    },
    editorReady(benefitsMomentTemplate) {
      this.emailEditorReady = true;
      // Set emailEditorName variable
      let emailEditorName = `emailEditor_${benefitsMomentTemplate.loyalty_benefits_moment_template_id_external}`;
      // Set the body values of the email editor
      this.$refs[emailEditorName].editor.setBodyValues({
        backgroundColor: "#F6F6F6",
        textColor: '#4A4A4A',
        contentAlignment: 'center',
        links: {
          color: '#4A4A4A',
          underline: true
        }
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
        // Get available email templates if user has permission
        if(this.platformPermissions.includes('core.emailtemplates') === true) this.getEmailTemplates();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    countdown() {
      // Calculate remaining points title length
      // if(this.benefitsMomentTemplate.points_title) this.remainingCount.points_title = this.maxCount.points_title - this.benefitsMomentTemplate.points_title.length;
      // Calculate remaining points description length
      // if(this.benefitsMomentTemplate.points_description) this.remainingCount.points_description = this.maxCount.points_description - this.benefitsMomentTemplate.points_description.length;
      // Calculate remaining push title length
      if(this.benefitsMomentTemplate.push_title) this.remainingCount.push_title = this.maxCount.push_title - this.benefitsMomentTemplate.push_title.length;
      // Calculate remaining push message length
      if(this.benefitsMomentTemplate.push_message) this.remainingCount.push_message = this.maxCount.push_message - this.benefitsMomentTemplate.push_message.length;
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.checkModules();
    this.getPlatformPermissions();
    this.getCompanyDetails();
  
    this.$bus.$on('email_template_added', (e) => {
      this.getEmailTemplates();
    });
  },
  beforeDestroy() {
    this.$bus.$off('email_template_added');
  }
}
</script>
